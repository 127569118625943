* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root,
html {
  background-color: lightgray;
}

/* html,
body,
#root {
  background-color: rgb(91, 215, 224);
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgb(91, 215, 224);
  color: white;
}

.nav-left {
  width: 100vw;
  padding-left: 15px;
  font-size: 2rem;
}

.nav-right {
  display: flex;
  width: 100vw;
  max-width: 190px;
  justify-content: space-between;
  padding-right: 15px;
  font-size: 1.3rem;
}

.nav-right > div {
  cursor: pointer;
}

.sidebar-container {
  background-color: rgb(91, 215, 224);
  display: flex;
  height: calc(100vh - 39px);
  flex-direction: column;
  padding: 0 0 0 10px;
  width: 115px;
  gap: 10px;
  border-top: solid white;
}

li {
  cursor: pointer;
  padding: 10px 10px 10px 20px;
  list-style: none;
}

.main {
  display: flex;
}

.display {
  font-size: 1.4rem;
  padding: 40px 0 0 50px;
  background-color: rgb(183, 246, 250);
  flex: 1;
  border-radius: 50px 0 0 0;
  border-top: solid white 7px;
  border-left: solid white 7px;
}

.active {
  background-color: rgb(183, 246, 250);
  border-radius: 20px 0 0 20px;
}

.modal-pop {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.45);
}

.modal-content {
  background-color: aliceblue;
  padding: 25px;
  margin-bottom: 50vh;
  border-radius: 15px;
}

.modal-content button {
  text-align: center;
  margin-left: 20px;
}

.list-container {
  flex: 1;
}

.list-item {
  display: flex;
  width: 100%;
  max-width: 70vw;
  justify-content: center;
  margin: 10px;
}

.list-title {
  flex: 1;
}

.list-title input {
  background-color: rgb(91, 215, 224);
  border: none;
  font-size: inherit;
  border-radius: 5px;
}

.list-title input:focus {
  outline: none;
}

.list-buttons {
  display: flex;
  gap: 0.5rem;
  margin-right: 5px;
}

.list-buttons button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 5px 0 5px;
}

.button-span button {
  margin-left: 10px;
  border-radius: 5px;
  border: solid black 1px;
  color: black;
  width: 50px;
}

.delete-btn-container {
  padding: 20px 0 0 10px;
  height: 85px;
  position: relative;
}

.delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 0 30px 20px;
  transform: scale(1.7);
} */
